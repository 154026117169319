<template>
  <el-breadcrumb separator="/">
    <el-breadcrumb-item :to="{ path: '/ChargeMeTribe' }">首頁</el-breadcrumb-item>
    <el-breadcrumb-item>搜尋結果</el-breadcrumb-item>
  </el-breadcrumb>
  <el-row>
    <el-col v-for="item in cardsFilter" :key="item">
      <el-card :body-style="{ padding: '0px' }" @click="redirectToClass(item.id)">
        <img :src="item.imgUrl" />
        <div class="content">
          <div class="top">
            <span>{{ item.title }}</span>
            <span>{{ item.degree }}</span>
          </div>
          <div class="clearfix">{{ item.info }}</div>
          <div class="bottom">
            <span class="teacher">{{ item.teacher }}</span>
            <span class="price">${{ item.price }} / 堂</span>
          </div>
        </div>
      </el-card>
    </el-col>
  </el-row>
  <div class="pagination">
    <el-pagination layout="prev, pager, next" :total="pageTotal" @current-change="handleCurrentChange" :page-size="pageSize" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: 1,
      pageSize: 10,
      ruleForm: [],
    };
  },
  computed: {
    pageTotal() {
      return this.ruleForm.length;
    },
    cardsFilter() {
      return this.ruleForm.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page);
    },
  },
  async created() {
    await this.initData();
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
      window.scrollTo(0,0);
    },
    async initData() {
      const vm = this;
      vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/v2/Courses?InstrumentId=${vm.$store.state.searchData.instrument}&MinPrice=${vm.$store.state.searchData.minPrice}&MaxPrice=${vm.$store.state.searchData.maxPrice}&TeacherName=${vm.$store.state.searchData.name}`)
        .then(res => {
          res.data.courses.forEach(item => {
            vm.ruleForm.push({
              imgUrl: vm.imageProcess(item.cover, "cover"),
              title: item.name,
              price: item.price,
              info: item.introduction,
              teacher: item.teacherName,
              degree: vm.getDegree(item.degree),
              id: item.id,
            });
          });
        })
        .catch(err => {
          console.log(err);
        });
    },
    redirectToClass(id) {
      this.$router.push(`/ChargeMeTribe/Class/${id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
.el-breadcrumb {
  padding: 1.5rem 2rem;
  font-size: 1rem;
}
.el-row {
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.el-card {
  width: 80vw;
  margin-bottom: 1.5rem;
  cursor: pointer;
  &:hover {
    box-shadow: 3px 3px 5px 3px rgb(206, 205, 205);
  }
  :deep .el-card__body {
    display: flex;
    text-align: start;
    height: 100%;
    img {
      width: 40%;
      display: block;
      object-fit: cover;
    }
    .content {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      span {
        font-weight: 900;
        font-size: 1.1rem;
      }
      .top {
        display: flex;
        justify-content: space-between;
      }
      .clearfix {
        margin: 0.5rem 0;
        line-height: 1.2rem;
        font-size: 0.8rem;
        flex-grow: 1;
      }
      .bottom {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 3rem;
  .el-pagination {
    align-items: flex-end;
  }
}
@media screen and (min-width: 768px) {
  .el-card {
    height: 30vh;
    width: 60vw;
  }
}
</style>
